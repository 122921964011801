




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'HighQualityWork',
  components: {
    GoodHomeworkPhone: () => import('./phone.vue'),
    GoodHomeworkDesk: () => import('./desk.vue')
  },
  setup() {
    const zyType = computed(() =>
      AppModule.device === DeviceType.Desktop
        ? 'GoodHomeworkDesk'
        : 'GoodHomeworkPhone'
    )
    return { zyType }
  }
})
